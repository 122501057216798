import React from 'react';
import './App.css';
import Sidebar from './Sidebar';

function App() {
  return (
    <div>
      <div className="app">
        <Sidebar>
          
        </Sidebar>
        {/* Contenido principal de la página */}
      </div>
      <main class='snap-y snap-mandatory relative w-full h-screen overflow-auto'>
        <div class='snap-center'>
          <section id="aa" class="bg-black h-screen w-screen text-center overflow-hidden relative">
            <video autoplay="autoplay" muted>
              <source src="home-intro.mp4" type="video/mp4"></source>
            </video>
          </section>
        </div>
        <div class='snap-center'>
          <section id="bb" class="bg-black h-screen w-screen text-center overflow-hidden relative">
            <video autoplay="autoplay" muted>
              <source src="header-menu.mp4" type="video/mp4"></source>
            </video>
          </section>
        </div>
      </main>
    </div>
  );
}

export default App;