import React, { useState } from 'react';
import './Sidebar.css';

function Sidebar() {
  const [showSidebar, setShowSidebar] = useState(false);

  return (
    <div>
        <div className='menu' onClick={() => setShowSidebar((prev) => !prev)}>
            <div class="container">
                
            </div>
        </div>
        <div className={`menu nav ${showSidebar ? 'hide-menu' : 'hide-menu'}`}>
            <div className='buttonHome'>
                HOME
            </div>
        </div>
    </div>
  );
}

export default Sidebar;
